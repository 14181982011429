<template>
  <div class="bg-white p-4 rounded-lg items-start shadow">
    <routerLink :to="link" class="flex items-start space-x-2">
      <span>
        <slot />
      </span>
      <div>
        <span class="font-bold blue">{{ title }}</span>
        <div>
          <span
            class="text-sm ffamily-pro blue"
            v-html="subtitle"
          >
          </span>
        </div>
      </div>
    </routerLink>
  </div>
</template>

<script>
export default {
    props: [
        'link',
        'title',
        'subtitle'
    ]
};
</script>

<style scoped>
.blue {
    color: #222F5A;
}
</style>