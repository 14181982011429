<template>
  <div class="bg-lg h-full">
    <toast-message
      :show="showToast"
      @close="showToast = false"
      message="Vous devez séléctionnez une période"
    />
    <toast-message
      :show="toast.show"
      @close="toast.show = false"
      :color="toast.color"
      :message="toast.message"
      style="top: 16px; right: 16px"
    >
      <span slot="icon">
        <component :is="toast.icon" />
      </span>
    </toast-message>
    <toast-message
      :show="billSent"
      @close="showToast = false"
      :message="$t('counterRecharge.bill_sent')"
    />
    <toast-message
      :show="billSentError"
      @close="showToast = false"
      :message="$t('counterRecharge.bill_sent_error')"
    />
    <div class="p-5 sm:p-10 grid grid-cols-1">
      <span
        class="
          col-span-1
          font-semibold
          text-lg text-tex-3
          sm:text-black
          ffamily-pro
        "
        >{{ $t("homeDash.titleBtn") }}</span
      >

      <div class="grid gridCols gap-4">
        <purchase-card
          link="/dashboard/pay"
          :title="$t('homeDash.payTitle')"
          :subtitle="$t('homeDash.payText')"
        >
          <Refill />
        </purchase-card>

        <purchase-card
          link="/dashboard/recharge"
          :title="$t('homeDash.rechargTitle')"
          :subtitle="$t('homeDash.rechargSubtitle')"
        >
          <Iconcompt2 />
        </purchase-card>

        <purchase-card
          link="/dashboard/counters"
          :title="$t('homeDash.saveTitle')"
          :subtitle="$t('homeDash.saveSubtitle')"
        >
          <Iconsave />
        </purchase-card>

        <purchase-card
          link="/dashboard/bills"
          :title="$t('homeDash.addcTitle')"
          :subtitle="$t('homeDash.addcSubtitle')"
        >
          <Iconpay />
        </purchase-card>
      </div>

      <div
        class="
          ffamily-pro
          col-span-1
          font-semibold
          text-lg
          mt-12
          mb-4
          flex
          justify-between
          text-tex-3
          sm:text-black
        "
      >
        <span>{{ $t("homeDash.tablTitle") }}</span>

        <calendar-picker @change="onCalendarChange" />
      </div>

      <tabs
        :items="[
          {
            key: 'paiement-facture',
            title: $t('rechargeSuccess.billPayments'),
            active: true,
          },
          {
            key: 'recharge-compteur',
            title: $t('rechargeSuccess.meterRefills'),
            active: false,
          },
        ]"
      >
        <template v-slot:paiement-facture>
          <div id="recharge-compteur">
            <div v-if="bills.length !== 0" class="bg-white col-span-1">
              <table
                class="
                  w-full
                  table-auto
                  xl:table-fixed
                  text-sm
                  border border-solid
                  ffamily-pro
                "
              >
                <thead
                  class="
                    hidden
                    lg:table-header-group
                    font-bold
                    text-left
                    border border-bgk-2 border-solid
                  "
                >
                  <th class="p-2 sm:p-3">
                    {{ $t("rechargePay.subscriberNumber") }}
                  </th>
                  <th>{{ $t("rechargePay.amount") }}</th>
                  <th>{{ $t("homeDash.date") }}</th>
                  <th>{{ $t("homeDash.action") }}</th>
                </thead>
                <tbody>
                  <tr
                    class="hover:bg-bgk-1 border border-bgk-2 border-solid"
                    v-for="bill in bills"
                    :key="bill._id"
                  >
                    <td class="flex flex-col p-3">
                      <span class="text-sm">{{
                        bill.subscriber.reference
                      }}</span>
                      <span class="text-xs" style="color: #4d4c4c">{{
                        bill.subscriber.name
                      }}</span>
                    </td>
                    <td class="text-right lg:text-left">
                      <span>{{ bill.amount }} Fcfa</span><br />
                    </td>

                    <td>
                      <span class="hidden lg:table-cell">{{
                        getDate(bill.updatedAt)
                      }}</span>
                    </td>
                    <td class="px-2 lg:px-0 w-8 lg:w-auto">
                      <div class="lg:flex hidden">
                        <div class="flex items-center">
                          <button
                            class="focus:outline-none mr-2"
                            :title="$t('tooltip.downloadbill')"
                            @click="downloadPostBill(bill._id)"
                          >
                            <Icondownl />
                          </button>
                          <button
                            class="focus:outline-none mr-2"
                            :title="$t('tooltip.sendbill')"
                            @click="passSendPostPurchase(bill._id)"
                          >
                            <Iconsend />
                          </button>
                        </div>
                      </div>
                      <div class="lg:hidden">
                        <Icondatatabl
                          @click="bill.active = !bill.active"
                          class=""
                        />
                        <div
                          v-show="bill.active == true"
                          @click="bill.active = !bill.active"
                          class="
                            absolute
                            -ml-24
                            bg-white
                            flex flex-col
                            p-4
                            shadow-xs
                            rounded
                          "
                        >
                          <button
                            class="focus:outline-none flex items-center mb-1"
                            :title="$t('tooltip.downloadbill')"
                            @click="downloadPostBill(bill._id)"
                          >
                            <Icondownl class="mr-3" /><span>{{
                              $t("homeDash.actionDown")
                            }}</span>
                          </button>
                          <button
                            class="focus:outline-none flex items-center mb-1"
                            :title="$t('tooltip.sendbill')"
                            @click="passSendPostPurchase(bill._id)"
                          >
                            <Iconsend class="mr-3" /><span>{{
                              $t("homeDash.actionSend")
                            }}</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                v-show="pages.length > 1"
                class="p-4 text-right border border-bgk-2"
              >
                <nav>
                  <ul>
                    <li>
                      <button
                        :class="
                          currentPage === pageNumber
                            ? 'bg-red-600 text-white'
                            : 'bg-white'
                        "
                        class="
                          focus:outline-none
                          text-center text-xs
                          rounded-full
                          h-5
                          w-5
                        "
                        v-for="pageNumber in pages"
                        :key="pageNumber.id"
                        @click="gotoPage(pageNumber)"
                      >
                        {{ pageNumber }}
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div v-else>
              <KdEmptyState />
            </div>
          </div>
        </template>

        <template v-slot:recharge-compteur>
          <div id="recharge-compteur">
            <div v-if="purchases.length !== 0" class="bg-white col-span-1">
              <table
                class="
                  w-full
                  table-auto
                  xl:table-fixed
                  text-sm
                  border border-solid
                  ffamily-pro
                "
              >
                <thead
                  class="
                    hidden
                    lg:table-header-group
                    font-bold
                    text-left
                    border border-bgk-2 border-solid
                  "
                >
                  <th class="p-2 sm:p-3">{{ $t("homeDash.meterNumb") }}</th>
                  <th>{{ $t("homeDash.metrAmount") }}</th>
                  <th>{{ $t("homeDash.date") }}</th>
                  <th>{{ $t("homeDash.action") }}</th>
                </thead>
                <tbody>
                  <tr
                    class="hover:bg-bgk-1 border border-bgk-2 border-solid"
                    v-for="purchase in purchases"
                    :key="purchase.id"
                  >
                    <td class="flex flex-col p-3">
                      <span class="text-sm">{{ purchase.counter }}</span>
                      <span class="text-xs" style="color: #4d4c4c">{{
                        purchase.cUser
                      }}</span>
                    </td>
                    <td class="text-right lg:text-left">
                      <span>{{ purchase.amount }} Fcfa</span><br />
                      <span class="lg:hidden">{{ purchase.date }}</span>
                    </td>

                    <td>
                      <span class="hidden lg:table-cell">{{
                        purchase.date
                      }}</span>
                    </td>
                    <td class="px-2 lg:px-0 w-8 lg:w-auto">
                      <div class="lg:flex hidden">
                        <div class="flex items-center">
                          <button
                            class="focus:outline-none mr-2"
                            :title="$t('tooltip.downloadbill')"
                            @click="downloadBill(purchase.transacId)"
                          >
                            <Icondownl />
                          </button>
                          <button
                            class="focus:outline-none mr-2"
                            :title="$t('tooltip.sendbill')"
                            @click="passSendPurchase(purchase.transacId)"
                          >
                            <Iconsend />
                          </button>
                        </div>
                        <div class="border-l-2">
                          <button
                            class="focus:outline-none flex items-center ml-2"
                            :title="$t('tooltip.renew')"
                            @click="
                              renouveller(purchase.counter, purchase.cUser)
                            "
                          >
                            <Iconrefresh />
                            <span
                              class="text-sm hidden lg:block font-semibold ml-2"
                              style="color: #0965f6"
                              >{{ $t("homeDash.actionRefresh") }}</span
                            >
                          </button>
                        </div>
                      </div>
                      <div class="lg:hidden">
                        <Icondatatabl
                          @click="purchase.active = !purchase.active"
                          class=""
                        />
                        <div
                          v-show="purchase.active == true"
                          @click="purchase.active = !purchase.active"
                          class="
                            absolute
                            -ml-24
                            bg-white
                            flex flex-col
                            p-4
                            shadow-xs
                            rounded
                          "
                        >
                          <button
                            class="focus:outline-none flex items-center mb-1"
                            :title="$t('tooltip.downloadbill')"
                            @click="downloadBill(purchase.transacId)"
                          >
                            <Icondownl class="mr-3" /><span>{{
                              $t("homeDash.actionDown")
                            }}</span>
                          </button>
                          <button
                            class="focus:outline-none flex items-center mb-1"
                            :title="$t('tooltip.sendbill')"
                            @click="passSendPurchase(purchase.transacId)"
                          >
                            <Iconsend class="mr-3" /><span>{{
                              $t("homeDash.actionSend")
                            }}</span>
                          </button>
                          <button
                            class="focus:outline-none flex items-center"
                            :title="$t('tooltip.renew')"
                            @click="
                              renouveller(purchase.counter, purchase.cUser)
                            "
                          >
                            <Iconrefresh class="mr-3" /><span>{{
                              $t("homeDash.actionRefresh")
                            }}</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                v-show="pages.length > 1"
                class="p-4 text-right border border-bgk-2"
              >
                <nav>
                  <ul>
                    <li>
                      <button
                        :class="
                          currentPage === pageNumber
                            ? 'bg-red-600 text-white'
                            : 'bg-white'
                        "
                        class="
                          focus:outline-none
                          text-center text-xs
                          rounded-full
                          h-5
                          w-5
                        "
                        v-for="pageNumber in pages"
                        :key="pageNumber.id"
                        @click="gotoPage(pageNumber)"
                      >
                        {{ pageNumber }}
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div v-else>
              <KdEmptyState />
            </div>
          </div>
        </template>
      </tabs>
    </div>
    <KdSendModal
      :isPost="isPost"
      @sent="onSent"
      @success="onSendSuccess"
      @fail="onSendFail"
    />
  </div>
</template>

<script>
import KdSendModal from "./kdSendModal";
import VcDatePicker from "v-calendar/lib/components/date-picker.umd";
import Iconcompt2 from "../../assets/svg/dashbIcons/compteurlogo.svg?inline";
import Refill from "../../assets/svg/dashbIcons/refill.svg?inline";
import Iconsave from "../../assets/svg/dashbIcons/save.svg?inline";
import Iconpay from "../../assets/svg/dashbIcons/paymentcard.svg?inline";
import Icondownl from "../../assets/svg/dashbIcons/downloadblue.svg?inline";
import Iconsend from "../../assets/svg/dashbIcons/sendgreen.svg?inline";
import Iconrefresh from "../../assets/svg/dashbIcons/refresh.svg?inline";
import Icondatatabl from "../../assets/svg/dashbIcons/datatablmb.svg?inline";
import Iconchevroncal from "../../assets/svg/dashbIcons/chevroncalendar.svg?inline";
import Iconcalendar from "../../assets/svg/dashbIcons/calendar.svg?inline";
import Iconchevrdate from "../../assets/svg/dashbIcons/chevronDate.svg?inline";
import IconSuccess from "../../assets/svg/icons/toascheck.svg?inline";
import IconError from "../../assets/svg/icons/toasterror.svg?inline";
import ToastMessage from "../../components/ToastMessage.vue";
import KdEmptyState from "../../components/dashboardComponents/KdEmptyState.vue";
import Tabs from "../../components/Tabs.vue";
import CalendarPicker from "../../components/CalendarPicker.vue";
import PurchaseCard from "../../components/PurchaseCard.vue";
import KdInvoicePaiement from "../../components/dashboardComponents/KdInvoicePaiement";
import axios from "axios";
export default {
  components: {
    Refill,
    Iconcompt2,
    Iconsave,
    Iconpay,
    Icondownl,
    Iconsend,
    Iconrefresh,
    Icondatatabl,
    Iconchevroncal,
    Iconcalendar,
    KdSendModal,
    Iconchevrdate,
    IconSuccess,
    IconError,
    ToastMessage,
    VcDatePicker,
    Tabs,
    KdEmptyState,
    CalendarPicker,
    PurchaseCard,
    KdInvoicePaiement,
  },
  data() {
    return {
      calFilter: [
        this.$i18n.t("homeDash.calBtn"),
        this.$i18n.t("homeDash.today"),
        this.$i18n.t("homeDash.passWeek"),
        this.$i18n.t("homeDash.passMonth"),
        this.$i18n.t("homeDash.passYear"),
      ],
      selectedFilter: this.$i18n.t("homeDash.calBtn"),
      chooseDate: false,
      calendar: false,
      showToast: false,
      isPost: false,
      postId: null,
      //checkin: '',
      page: 1,
      pages: [],
      toast: {
        show: false,
        message: "",
        icon: "IconSuccess",
        color: "bg-bgk-3",
      },
      billSent: false,
      billSentError: false,
    };
  },

  methods: {
    getDate(value) {
      const v = new Date(value);
      const d = v.toLocaleDateString().split("/").join(".");
      const h = v.getHours() + ":" + v.getMinutes();
      return `${d} à ${h}`;
    },
    addMeter() {
      this.$store.commit("mutAddCounter", true);
    },

    onCalendarChange(value) {
      console.log(value);
      const { startDate, endDate } = value;
      this.$store.commit(
        "mutBills",
        startDate === "allTime" ? {} : { startDate, endDate }
      );
      this.getPurchases(1, value);
    },

    downloadBill(transacId) {
      this.$store
        .dispatch("downloadBillRequest", transacId)
        .then((data) => {
          window.open(data, "_blank");
        })
        .catch(() => {
          const toast = {
            show: true,
            message: this.$t("rechargeSuccess.downloadErrorMessage"),
            icon: "IconError",
            color: "bg-bgk-6",
          };
          this.toast = toast;
        });
    },
    downloadPostBill(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/transactions/download/${id}`)
        .then((response) => {
          window.open(response.data, "_blank");
        });
    },
    renouveller(numb, user) {
      const meter = {
        cNumb: numb,
        cUser: user,
      };
      this.$store.commit("mutSelectedMeter", meter);
      this.$store.commit("mutCheckArrear");
      this.$router.push("/dashboard/recharge");
    },
    getPurchases(page, filter) {
      const data = {
        page: page,
        startDate: filter.startDate,
        endDate: filter.endDate,
      };
      this.$store.commit("mutPurchase", data);
    },
    gotoPage(pageNumber) {
      this.page = pageNumber;
      this.getPurchases(pageNumber, this.filterRange);
    },
    setPages() {
      let numberOfpages = Math.ceil(this.numbOfPages);
      this.pages = [];
      for (let index = 1; index <= numberOfpages; index++) {
        this.pages.push(index);
      }
    },

    passDeletePurchase(id) {
      this.$store.commit("mutPurchaseId", id);
      this.$store.commit("mutTrashPurchase", true);
    },
    passSendPurchase(id) {
      this.$store.commit("mutTransacId", id);
      this.$store.commit("mutSendPurchase", true);
    },
    passSendPostPurchase(id) {
      this.postId = id;
      this.isPost = true;
      this.$store.commit("mutSendPurchase", true);
    },
    onSent(value) {
      if (value.email) {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "/notifications/email", {
            email: value.email,
            transactionId: this.postId,
          })
          .then(() => {
            this.billSent = true;
            setTimeout(() => {
              this.billSent = false;
            }, 3000);
          })
          .catch(() => {
            this.billSentError = true;
            setTimeout(() => {
              this.billSentError = false;
            }, 3000);
          });
      }

      if (value.smsTel) {
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "/notifications/sms", {
            phone: value.countryCode + " " + value.smsTel,
            transactionId: this.postId,
          })
          .then(() => {
            this.billSent = true;
            setTimeout(() => {
              this.billSent = false;
            }, 3000);
          })
          .catch(() => {
            this.billSentError = true;
            setTimeout(() => {
              this.billSentError = false;
            }, 3000);
          });
      }
    },
    onSendSuccess() {
      const toast = {
        show: true,
        message: this.$t("rechargeSuccess.toastMessage"),
        icon: "IconSuccess",
        color: "bg-bgk-3",
      };
      this.toast = toast;
    },

    onSendFail() {
      const toast = {
        show: true,
        message: this.$t("rechargeSuccess.toastErrorMessage"),
        icon: "IconError",
        color: "bg-bgk-6",
      };
      this.toast = toast;
    },
  },
  computed: {
    purchases() {
      return this.$store.getters.getPurchases;
    },
    bills() {
      return this.$store.getters.getBills;
    },
    numbOfPages() {
      return this.$store.getters.getNumbOfPages;
    },

    currentPage() {
      return this.page;
    },
  },
  watch: {
    numbOfPages() {
      this.setPages();
    },
  },
  mounted() {
    //this.getPurchases(1);
    const data = {
      page: 1,
      startDate: "allTime",
      endDate: "endAllTime",
    };
    this.$store.commit("mutPurchase", data);
    this.$store.commit("mutBills");
    this.setPages();
  },
};
</script>

<style scoped>
.bg-lg {
  background-image: url(../../assets/svg/SBEEA1.svg);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;
}
.gridCols {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}
@media (min-width: 640px) {
  .bg-lg {
    background-image: url(../../assets/svg/Bg.svg);
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: cover;
  }
}
</style>
